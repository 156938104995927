import { useState, useContext } from "react";
import {
  Button,
  Flex,
  View,
  Icon,
  Text,
  TextField,
  Card,
} from "@aws-amplify/ui-react";

import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  DistanceMatrixService,
  Autocomplete,
} from "@react-google-maps/api";
import { PzPrimeContext } from "../Context/ProposalContext";

const placesLibrary = ["places"];

const MapAndAddressSelector = ({
  isInstantQuoteForm,
  setChangeInInputValues,
  changeInInputValues,
  setIsProjectNameEntered,
  setQuoteRequirements,
  isProjectNameEntered,
  isRequirementMet,
  setIsWarningVisible,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: placesLibrary,
  });

  const [searchResult, setSearchResult] = useState("Result: none");

  const [enteredAddress, setEnteredAddress] = useState("");

  const [changeInLocation, setChangeInLocation] = useState(false);

  const {
    address,
    setAddress,
    distanceMatrixData,
    setDistanceMatrixData,
    location,
    setLocation,
    colorMode,
    changeInInput,
    setChangeInInput,
    setProjectName,
    openMissingFields,
  } = useContext(PzPrimeContext);

  const containerStyle = {
    width: "auto",
    height: "inherit",
    borderRadius: "10px",
  };

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      location.lat = place.geometry.location.lat();
      location.lng = place.geometry.location.lng();
      setLocation(location);
      setAddress(place.formatted_address);
      setEnteredAddress(place.formatted_address);
      setChangeInLocation(true);
      if (isInstantQuoteForm) {
        setChangeInInputValues(!changeInInputValues);
        setQuoteRequirements("");
      } else {
        if (!openMissingFields) {
          setChangeInInput(!changeInInput);
        }
      }
    } else {
      alert("Please enter text");
    }
  }

  function getNextMonday() {
    const now = new Date();
    const today = new Date(now);
    today.setMilliseconds(0);
    today.setSeconds(0);
    today.setMinutes(0);
    today.setHours(8);

    const nextMonday = new Date(today);

    do {
      nextMonday.setDate(nextMonday.getDate() + 1); // Adding 1 day
    } while (nextMonday.getDay() !== 1);
    return nextMonday;
  }

  if (!isLoaded) return <div>Loading...</div>;

  function GoogleMapComponent(loc) {
    setChangeInLocation(false);
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={loc.loc}
        options={{
          disableDefaultUI: "true",
          mapTypeControl: true,
        }}
        zoom={18}
      >
        <MarkerF position={loc.loc} />
      </GoogleMap>
    );
  }

  const Css = `
  .pac-container { 
    height:auto;
  }
`;

  const darkCss = `
.pac-container,.pac-container.pac-logo { 
  height:auto;
  background:#2e3436;
  color: #fff;
}
.pac-item, .pac-item-query { 
  color: #fff;
}
.pac-item:hover, pac-item-selected {
  background-color: #151515;
}
.pac-item pac-item-selected {
  background-color: #151515;
}
`;

  return (
    <>
      <style>{colorMode === "dark" ? darkCss : Css}</style>
      <Card
        display={"flex"}
        direction={"column"}
        width={"100%"}
        padding={"0px"}
        backgroundColor={colorMode === "dark" && "#2e3436"}
        borderRadius={"10px"}
      >
        <Text
          fontSize={{
            base: "16px",
            small: "18px",
            medium: "22px",
          }}
          marginBottom={"10px"}
        >
          Building Address
        </Text>
        <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
          <TextField
            labelHidden
            type="text"
            placeholder={"1212 Westover Hills Blvd, Richmond, VA 23225"}
            value={address ? address : enteredAddress}
            hasError={
              isInstantQuoteForm
                ? !isProjectNameEntered || (enteredAddress && !address)
                : !isRequirementMet && address.length === 0
            }
            errorMessage={
              enteredAddress &&
              !address &&
              "Invalid address - Please select from dropdown or leave empty"
            }
            onChange={(e) => {
              setEnteredAddress(e.target.value);
              setAddress("");
              if (isInstantQuoteForm) {
                setChangeInInputValues(!changeInInputValues);
                setIsProjectNameEntered(true);
                setQuoteRequirements("");
              } else {
                setIsWarningVisible(false);
              }
            }}
            outerEndComponent={
              <Button
                onClick={() => {
                  setEnteredAddress("");
                  setAddress("");
                  if (isInstantQuoteForm) {
                    setChangeInInputValues(!changeInInputValues);
                    setQuoteRequirements("");
                  }
                }}
              >
                <Icon
                  ariaLabel="Close"
                  pathData="M6.2253 4.81108C5.83477 4.42056 5.20161 4.42056 4.81108 4.81108C4.42056 5.20161 4.42056 5.83477 4.81108 6.2253L10.5858 12L4.81114 17.7747C4.42062 18.1652 4.42062 18.7984 4.81114 19.1889C5.20167 19.5794 5.83483 19.5794 6.22535 19.1889L12 13.4142L17.7747 19.1889C18.1652 19.5794 18.7984 19.5794 19.1889 19.1889C19.5794 18.7984 19.5794 18.1652 19.1889 17.7747L13.4142 12L19.189 6.2253C19.5795 5.83477 19.5795 5.20161 19.189 4.81108C18.7985 4.42056 18.1653 4.42056 17.7748 4.81108L12 10.5858L6.2253 4.81108Z"
                />
              </Button>
            }
          />
        </Autocomplete>
      </Card>
      <View
        height={{
          base: "200px",
          small: "300px",
          medium: "400px",
        }}
        width={"100%"}
        borderRadius={"15px"}
        // overflow={"hidden"}
        // boxShadow={"large"}
        backgroundColor={colorMode === "dark" && "#2e3436"}
        // padding={"15px"}
      >
        {changeInLocation ? (
          <GoogleMapComponent loc={location} />
        ) : (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={location}
            options={{
              disableDefaultUI: "true",
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                position: window.google.maps.ControlPosition.TOP_RIGHT,
              },
            }}
            zoom={18}
            className="custom-map-container"
          >
            <MarkerF position={location} />
          </GoogleMap>
        )}

        <DistanceMatrixService
          options={{
            destinations: [location],
            origins: [{ lat: 37.5202399, lng: -77.48918379999999 }],
            travelMode: "DRIVING",
            drivingOptions: {
              departureTime: getNextMonday(),
              trafficModel: "pessimistic",
            },
          }}
          callback={(response) => {
            if (response.rows[0].elements[0].status === "OK") {
              distanceMatrixData.distance =
                response.rows[0].elements[0].distance?.value;
              distanceMatrixData.time =
                response.rows[0].elements[0].duration_in_traffic?.value;
            } else {
              distanceMatrixData.distance = 0;
              distanceMatrixData.time = 0;
            }
            console.log(distanceMatrixData, "distanceMatrixData");
            console.log(response, "response");
            setDistanceMatrixData(distanceMatrixData);
          }}
        />
      </View>
    </>
  );
};

export default MapAndAddressSelector;
