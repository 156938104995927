import React, { useContext } from "react";
import { Flex, Button, Icon, Divider, Text } from "@aws-amplify/ui-react";
import { PzPrimeContext } from "../Context/ProposalContext";

const ProjectBuilderNavHeader = (props) => {
  const { backArrowFunction, title, ...rest } = props;
  const { colorMode } = useContext(PzPrimeContext);

  return (
    <Flex
      {...rest}
      gap={{
        base: "10px",
        small: "10px",
        medium: "20px",
      }}
      left={"0px"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
    >
      {/* <Flex alignItems={"center"}> */}
      <Button
        variation="link"
        textAlign={"center"}
        padding={{
          base: "5px 10px 5px 0px",
          small: "8px 12px 8px 5px",
          medium: "10px 30px 10px 10px",
        }}
        margin={{
          base: "0px",
          small: "0 5px",
          medium: "0px 15px",
        }}
        onClick={backArrowFunction}
      >
        <Icon
          ariaLabel="Close"
          fontSize={{
            base: "18px",
            small: "22px",
            medium: "25px",
          }}
          pathData="M33.3332 13.75H13.0498L22.3665 6.7625L19.9998 5L6.6665 15L19.9998 25L22.3498 23.2375L13.0498 16.25H33.3332V13.75Z"
        />
      </Button>
      {/* </Flex> */}
      <Divider height={"25px"} orientation="vertical" />
      <Text
        fontSize={{
          base: "16px",
          small: "20px",
          medium: "23px",
        }}
      >
        {title}
      </Text>
    </Flex>
  );
};

export default ProjectBuilderNavHeader;
