import {
  Alert,
  Button,
  Flex,
  Heading,
  Text,
  View,
} from "@aws-amplify/ui-react";
import { useState, useContext, useEffect } from "react";
import { PzPrimeContext } from "../Context/ProposalContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import ProjectBuilderNavHeader from "./ProjectBuilderNavHeader";

const MarketSelector = () => {
  const navig = useNavigate();

  const {
    selectedMarkets,
    setSelectedMarkets,
    setCurrentStage,
    activeStage,
    setActiveStage,
    stageValue,
    currentStage,
    colorMode,
    changeInInput,
    setChangeInInput,
    setFilterMarkets,
    requirementChecker,
    setIsProjectSummaryOpen,
  } = useContext(PzPrimeContext);

  const [isWarningVisible, setIsWarningVisible] = useState(false);

  const totalMarkets = [
    {
      id: "1",
      name: "Multifamily & Mixed Use",
      imagePath: () => {
        return require("../Assets/marketThemes/multifam.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_multifam.jpg");
      },
      selected: false,
    },
    {
      id: "2",
      name: "Office and Retail",
      imagePath: () => {
        return require("../Assets/marketThemes/office.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_office.jpg");
      },
      selected: false,
    },
    {
      id: "3",
      name: "Food and Beverage",
      imagePath: () => {
        return require("../Assets/marketThemes/kitchen.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_kitchen.jpg");
      },
      selected: false,
    },
    {
      id: "4",
      name: "Healthcare",
      imagePath: () => {
        return require("../Assets/marketThemes/healthcare.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_healthcare.jpg");
      },
      selected: false,
    },
    {
      id: "5",
      name: "Core / Shell",
      imagePath: () => {
        return require("../Assets/marketThemes/core.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_core.jpg");
      },
      selected: false,
    },
    {
      id: "6",
      name: "Warehouse / Storage",
      imagePath: () => {
        return require("../Assets/marketThemes/warehouse.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_warehouse.jpg");
      },
      selected: false,
    },
    {
      id: "7",
      name: "Single Family Residential",
      imagePath: () => {
        return require("../Assets/marketThemes/singlefam.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_singlefam.jpg");
      },
      selected: false,
    },
    {
      id: "8",
      name: "Religious and Cultural",
      imagePath: () => {
        return require("../Assets/marketThemes/religious1.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_religious1.jpg");
      },
      selected: false,
    },
    {
      id: "9",
      name: "Education and Daycare",
      imagePath: () => {
        return require("../Assets/marketThemes/school.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_school.jpg");
      },
      selected: false,
    },
    {
      id: "10",
      name: "Factory | Industrial | Garage",
      imagePath: () => {
        return require("../Assets/marketThemes/factory.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_factory.jpg");
      },
      selected: false,
    },
    {
      id: "11",
      name: "Theaters & Event Spaces",
      imagePath: () => {
        return require("../Assets/marketThemes/theater.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/marketThemes/small_theater.jpg");
      },
      selected: false,
    },
    {
      id: "12",
      name: "Other",
      imagePath: () => {
        return require("../Assets/package_perfectionist.jpg");
      },
      imagePathLPH: () => {
        return require("../Assets/package_perfectionist.jpg");
      },
      selected: false,
    },
  ];

  const [markets, setMarkets] = useState(totalMarkets);

  const customCss = `
  .cursor{
      cursor:pointer;
  } 
  .pointerEvents{
    pointer-events: none; 
    user-select:none;
  }
  .selected{
    background-color:#660066;
  }
  .selectedFont{
    color:#fff;
  }
  .hover:hover{
    -webkit-box-shadow: 0px 0px 12px 2px rgba(174,179,183,1);
    -moz-box-shadow: 0px 0px 12px 2px rgba(174,179,183,1);
    box-shadow: 0px 0px 12px 2px rgba(174,179,183,1);
    transition: 0.2s ease-in;
  }

  `;

  const marketClicked = (market, e) => {
    // Add this to set a limit to the selected count

    // if (!e.target.classList.contains("selected")) {
    //   const selectedCount = markets.filter((todo) => todo.selected).length;
    //   if (selectedCount === 4) {
    //     return;
    //   }
    // }
    let sample = [];
    sample = selectedMarkets;
    if (sample.includes(market))
      sample = sample.filter((theme) => theme !== market);
    else sample = [...sample, market];
    setSelectedMarkets(sample);

    setMarkets(
      markets.map((market) =>
        market.id === e.target.getAttribute("data-id")
          ? { ...market, selected: !market.selected }
          : market
      )
    );
    setChangeInInput(!changeInInput);
    setIsWarningVisible(false);
  };

  if (requirementChecker("/market-selector")[0])
    navig(requirementChecker("/market-selector")[1]);

  useEffect(() => {
    var element = document.getElementById("marketSelector");
    element.scrollIntoView({ block: "start" });
  }, []);

  setCurrentStage("marketSelector");
  setActiveStage(stageValue[currentStage] - 1);

  return (
    <div id="marketSelector">
      <style>{customCss}</style>
      <ProjectBuilderNavHeader
        backArrowFunction={() => {
          navig("/project-details");
        }}
        title={"Market Selector"}
        display={{
          base: "flex",
          small: "flex",
          medium: "none",
          large: "none",
        }}
        position={"fixed"}
        top={{
          base: "90px",
          small: "110px",
          medium: "none",
          large: "none",
        }}
        width={"100%"}
        height={"50px"}
        padding={"10px 5px"}
        style={{
          zIndex: "9",
          // borderTop: "1px solid #c5c5c5",
          borderBottom: "1px solid #c5c5c5",
        }}
      />
      <Flex
        padding={{
          base: "10px",
          small: "20px",
          medium: "20px",
          large: "20px 25px",
        }}
        direction="column"
        alignItems="flex-start"
        gap={{
          base: "10px",
          small: "20px",
          medium: "22px",
        }}
        alignSelf="stretch"
        marginBottom={{
          base: "20px",
          small: "30px",
          medium: "45px",
          large: "30px",
        }}
        marginTop={{
          base: "50px",
          small: "50px",
          medium: "0",
          large: "0",
        }}
      >
        <Text
          fontSize={{
            base: "18px",
            small: "22px",
            medium: "25px",
            large: "30px",
          }}
          fontStyle="normal"
          fontWeight="500"
          lineHeight="120%"
        >
          Choose a market that most accurately describes your project from the
          themes listed below. You may select multiple markets for mixed-use and
          multi-projects.
        </Text>
        <Flex
          gap={{
            base: "10px",
            small: "15px",
            medium: "15px",
          }}
          direction={"row"}
          wrap={"wrap"}
          justifyContent={{
            base: "flex-start",
            small: "flex-start",
            medium: "flex-start",
          }}
          alignItems={"stretch"}
        >
          {markets.map((market, index) => (
            <Flex
              key={index}
              backgroundColor={colorMode === "dark" && "#2e3436"}
              width={{
                base: "150px",
                small: "150px",
                medium: "320px",
                large: "320px",
              }}
              // maxWidth={"300px"}
              borderRadius={"10px"}
              gap={"0px"}
            >
              <Flex
                direction={"column"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                padding={{
                  base: "10px",
                  small: "10px",
                  medium: "15px",
                }}
                borderRadius={"10px"}
                width={"100%"}
                border={"1px solid #c5c5c5"}
                className={`cursor hover${
                  selectedMarkets.includes(market.name) ? " selected" : ""
                }`}
                gap={"10px"}
                data-id={market.dataId}
                onClick={(e) => marketClicked(market.name, e)}
              >
                <View
                  border={"1px solid #c5c5c5"}
                  overflow={"hidden"}
                  className="pointerEvents"
                  height={{
                    base: "100px",
                    small: "100px",
                    medium: "205px",
                    large: "205px",
                  }}
                  borderRadius={"10px"}
                  width={"100%"}
                >
                  <LazyLoadImage
                    src={market.imagePath()}
                    alt={market.name}
                    placeholderSrc={market.imagePathLPH()}
                    height="100%"
                    width="100%"
                    effect="blur"
                    style={{
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </View>
                <Text
                  className={`cursor pointerEvents ${
                    selectedMarkets.includes(market.name) ? " selectedFont" : ""
                  }`}
                  margin={{
                    base: "5px",
                    small: "5px",
                    medium: "8px",
                  }}
                  textAlign={"center"}
                  fontSize={{
                    base: "15px",
                    small: "15px",
                    medium: "25px",
                    large: "25px",
                  }}
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="130%"
                >
                  {market.name}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>

      {isWarningVisible && (
        <Alert
          variation="warning"
          position={"fixed"}
          bottom={{
            base: "55px",
            small: "55px",
            medium: "90px",
          }}
          right={{
            base: "20px",
            small: "20px",
            medium: "100px",
            large: "420px",
          }}
          padding={{
            base: "10px",
            small: "10px",
            medium: "auto",
          }}
          fontSize={{
            base: "14px",
            small: "16px",
            medium: "auto",
          }}
          transition="all 0.9s ease"
          className="layerTop"
        >
          Select at least one Project Market to continue
        </Alert>
      )}
      <Flex
        backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
        style={{
          zIndex: "9",
          borderTop: "1px solid #c5c5c5",
          // borderBottom: "1px solid #c5c5c5",
        }}
        position={"fixed"}
        display={{
          base: "flex",
          small: "flex",
          medium: "flex",
          large: "none",
        }}
        bottom={"0px"}
        padding={"8px 2px"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        <Button
          marginRight={"20px"}
          minWidth={"120px"}
          width={{
            base: "auto",
            small: "150px",
            medium: "200px",
          }}
          padding={{
            base: "5px 10px",
          }}
          fontSize={{
            base: "14px",
            small: "16px",
            medium: "16px",
          }}
          backgroundColor={"#40AABF"}
          color={"#fff"}
          onClick={() => {
            if (selectedMarkets.length > 0) {
              setFilterMarkets(selectedMarkets);
              setChangeInInput(!changeInInput);
              if (activeStage < 4) setActiveStage(stageValue[currentStage]);
              setCurrentStage("spaceSelector");
              navig("/space-selector");
            } else {
              setIsWarningVisible(true);
            }
          }}
        >
          Next Step
        </Button>
      </Flex>
      <Button
        position={"fixed"}
        bottom={"25px"}
        display={{
          base: "none",
          small: "none",
          medium: "none",
          large: "flex",
        }}
        right={{
          base: "20px",
          small: "20px",
          medium: "80px",
          large: "440px",
          xl: "440px",
          xxl: "450px",
        }}
        minWidth={"120px"}
        width={"200px"}
        padding={"10px"}
        fontSize={"16px"}
        backgroundColor={"#40AABF"}
        color={"#fff"}
        onClick={() => {
          if (selectedMarkets.length > 0) {
            setFilterMarkets(selectedMarkets);
            setChangeInInput(!changeInInput);
            if (activeStage < 4) setActiveStage(stageValue[currentStage]);
            setCurrentStage("spaceSelector");
            navig("/space-selector");
          } else {
            setIsWarningVisible(true);
          }
        }}
        // disabled={requirementChecker('/space-selector')[0]}
      >
        Next Step
      </Button>
    </div>
  );
};

export default MarketSelector;
