import {
  Flex,
  Text,
  Heading,
  Card,
  View,
  Image,
  useAuthenticator,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
import { useContext, useState, useEffect, useRef } from "react";
import { PzPrimeContext } from "../Context";
import { DataStore, Storage } from "aws-amplify";
import { Organization, Project, Proposal } from "../models";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProjectCollection from "./ProjectCollection";
import ModalComponent from "./ModalComponent";
import ProjectDetails from "./ProjectDetails";

const HomePage = () => {
  const {
    setCurrentStage,
    colorMode,
    isAdmin,
    setGotToSignUp,
    isLoggedIn,
    setIsLoggedIn,
    swalWithDefaults,
    formatNumberUS,
    setOpenMissingFields,
    navigateToProjectBuilder,
    setProjectId,
    gadgetFunctions,
    getRequiredFieldStates,
    resetContextVariables,
    isMultiLayer,
    setIsMultiLayer,
  } = useContext(PzPrimeContext);

  const { route, toSignUp } = useAuthenticator((context) => [context.route]);

  const [projectList, setProjectList] = useState([]);

  const navig = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const containerRefHomePage = useRef(null);
  const [itemCount, setItemCount] = useState(0);
  const [isBottom, setIsBottom] = useState(false);
  const [isProjectLoaded, setIsProjectLoaded] = useState(false);
  const [openViewProposals, setOpenViewProposals] = useState(false);
  const [proposals, setProposals] = useState({});
  const [sortProposal, setSortProposal] = useState([]);

  const loadCount = 10; //Indicates the total number of projects loaded when scrolled to bottom
  useEffect(() => {
    if (isLoggedIn) {
      setIsLoggedIn(false);
      window.location.reload();
    }
    setIsProjectLoaded(false);
    DataStore.observeQuery(Project).subscribe(({ items }) => {
      items.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      setProjectList(items);
      if (items.length < loadCount) {
        setItemCount(items.length);
      } else {
        setItemCount(loadCount);
      }
      if (items.length == 0) {
        setIsProjectLoaded(true);
      } else {
        setIsProjectLoaded(false);
      }
    });

    // Get the projectId from the instant quote page and update the useContext variables of the project (signedIn user)
    if (searchParams.get("springBoardProject")) {
      getSpringBoardProjectRecord(searchParams.get("projectId"));
    }

    if (localStorage.getItem("springBoardProject") === "true") {
      let springProjectId = localStorage.getItem("projectId");
      localStorage.removeItem("springBoardProject");
      localStorage.removeItem("projectId");
      getSpringBoardProjectRecord(springProjectId);
    }

    // Get the projectId from the instant quote and navigate to login page (guest user)
    if (searchParams.get("guestUserAuthentication")) {
      getGuestUserProjectRecord(searchParams.get("projectId"));
    }

    if (searchParams.get("loadpage") === "legacy-quote") navig("/legacy-quote");
    if (searchParams.get("loadpage") === "quote") navig("/quote");
    if (searchParams.get("loadpage") === "embed-quote") navig("/embed-quote");
    if (searchParams.get("loadpage") === "edit-proposal")
      navig("/edit-proposal");
  }, []);

  if (localStorage.getItem("guestUserAuthentication") === "true") {
    localStorage.removeItem("guestUserAuthentication");
    getGuestUserProjectRecord(localStorage.getItem("projectId"));
  }

  // Check if the user is a signed in and update the guestFromIQ status
  if (
    localStorage.getItem("guestFromIQ") === "signedInUser" &&
    localStorage.getItem("userId")
  ) {
    // Set the guestFromIQ to hasUpdateProjectAccess in localStorage
    localStorage.setItem("guestFromIQ", "hasUpdateProjectAccess");
    updateProjectFromIQ();
  }

  // Check if the guestFromIQ canEditProject and get the project from datastore and set the useContext variables and navigate to the respective page
  if (
    localStorage.getItem("guestFromIQ") === "canEditProject" &&
    localStorage.getItem("projectId")
  ) {
    DataStore.query(Project, localStorage.getItem("projectId")).then((res) => {
      console.log("res from canEdit", res);
      navigateToProjectBuilder(res, "edit", navig);
    });
    localStorage.removeItem("guestFromIQ");
    localStorage.removeItem("projectId");
  }

  if (
    localStorage.getItem("isEditProject") === "false" &&
    localStorage.getItem("projectId")
  ) {
    DataStore.query(Project, localStorage.getItem("projectId")).then((res) => {
      console.log("res from isEdit", res);
      navigateToProjectBuilder(res, "edit", navig);
    });
    localStorage.removeItem("isEditProject");
    localStorage.removeItem("projectId");
  }

  function getSpringBoardProjectRecord(projectId) {
    DataStore.query(Project, projectId).then((res) => {
      console.log("res from getSpringBoardProjectRecord", res);
      navigateToProjectBuilder(res, "edit", navig);
    });
  }

  function getGuestUserProjectRecord(projectId) {
    DataStore.query(Project, projectId).then((res) => {
      console.log("res from guestFn", res);
      // Check if the project has editors and navigate to the project builder page when they tried to click the detailed estimate button from the IQ page once again, else go to login page
      if (res.editors.length > 0) {
        navigateToProjectBuilder(res, "edit", navig);
      } else {
        localStorage.setItem("guestFromIQ", "guest");
        localStorage.setItem("projectId", projectId);
        navig("/login");
      }
    });
  }

  async function updateProjectFromIQ() {
    // Get the userId and projectId from the localStorage and update the project with the userId as editor of the project
    let userId = localStorage.getItem("userId");
    let projectIdFromIQ = localStorage.getItem("projectId");
    console.log(projectIdFromIQ);
    setProjectId(projectIdFromIQ);
    let iQProject = await DataStore.query(Project, projectIdFromIQ);
    console.log("project from IQ", iQProject);
    console.log("user from IQ", userId);
    await DataStore.save(
      Project.copyOf(iQProject, (updated) => {
        updated.editors = [userId];
      })
    ).then(async (res) => {
      console.log("updated project", res);
      localStorage.removeItem("userId");
      let requiredFields = await getRequiredFieldStates(projectIdFromIQ);
      gadgetFunctions.verifyProjectInfo(
        projectIdFromIQ,
        navig,
        userId,
        setOpenMissingFields,
        requiredFields
      );
    });
  }

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        containerRefHomePage.current;
      // Check if the user has scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 30) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    };
    if (containerRefHomePage.current) {
      containerRefHomePage.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (containerRefHomePage.current) {
        containerRefHomePage.current.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, []);

  useEffect(() => {
    if (isBottom) {
      if (projectList.length < itemCount + loadCount) {
        setItemCount(projectList.length);
      } else {
        setItemCount(itemCount + loadCount);
      }
      console.log("Reached the bottom of the container!", itemCount);
    }
  }, [isBottom]);

  const Css = `
.cardHover{
  border:1px solid #c5c5c5;
}
.cardHover:hover{
  background:#e9f9fc;
  border:2px solid #c5c5c5;
}
.addFont{
 color:#aeb3b7;
}
.cardHover:hover .addFont{
 color:grey;
}

.flexWidth{
  width:100%;
}
.onhover{
  background:#c5c5c5;
}
.onhover:hover{
  background:lightblue;
  transition:0.6s ease;
}
.inlineEdit .amplify-input{
  padding: 3px;
  width: 70px;
  min-width: 30px;
  box-shadow: none;
  border:1px solid #c5c5c5;
}
`;

  const darkCss = `
.cardHover:hover{
  background:#00404d;
  border:2px solid #c5c5c5;
}
.addFont{
  color:#aeb3b7;
 }
 .cardHover:hover .addFont{
  color:#fff;
 }
`;

  function formatDate(date, format) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear().toString().substring(2);

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (format === "month") {
      return [month, day, year].join("/");
    } else {
      return [d.getFullYear(), month, day].join("/");
    }
  }

  function navigateTo(page) {
    if (page === "home") {
      navig("/home");
    } else if (page === "project") {
      navig("/project-details");
    } else if (page === "market") {
      navig("/market-selector");
    } else if (page === "space") {
      navig("/space-selector");
    } else if (page === "final") {
      navig("/final-checks");
    } else if (page === "package") {
      navig("/package-selector");
    }
  }

  async function showProposal(proposalId) {
    try {
      const proposal = await DataStore.query(Proposal, proposalId);
      console.log("proposal", proposal.pdfKey);
      const encodedProposalPDF = await Storage.get(`${proposal.pdfKey}`, {
        cacheControl: "no-cache",
        contentType: "application/pdf",
      });
      console.log("encodedProposalPDF", encodedProposalPDF);
      window.open(encodedProposalPDF);
    } catch (e) {
      console.log("error", e);
      swalWithDefaults.fire({
        icon: "error",
        title: "Something went wrong!",
        text: "Could not load proposal.",
      });
    }
  }

  function viewProposals(proposal) {
    console.log("selected Proposal", proposal);
    const sortedProposals = Object.keys(proposal).sort(function (a, b) {
      return new Date(b) - new Date(a);
    });
    setSortProposal(sortedProposals);
    setOpenViewProposals(true);
    setIsMultiLayer(true);
    setProposals(proposal);
  }

  setCurrentStage("homePage");
  const variation = useBreakpointValue([5, 3, 3, 2, 2, 2]);

  return (
    <Flex
      direction={"column"}
      padding={{
        base: "12px",
        small: "15px",
        medium: "25px",
      }}
      alignItems={"center"}
      ref={containerRefHomePage}
    >
      <style>{colorMode === "dark" ? Css + darkCss : Css}</style>

      <Flex
        direction={"column"}
        width={"100%"}
        alignItems={"center"}
        maxWidth={"1800px"}
      >
        <Flex
          width={"100%"}
          direction={{
            base: "column",
            small: "column",
            medium: "column",
            large: "row",
            xl: "row",
            xxl: "row",
          }}
        >
          <Card
            direction={{
              base: "row",
              small: "column",
              medium: "column",
              large: "row",
              xl: "row",
              xxl: "row",
            }}
            display={"flex"}
            justifyContent={{
              base: "flex-start",
              small: "center",
              medium: "center",
              large: "flex-start",
              xl: "flex-start",
              xxl: "flex-start",
            }}
            alignItems={"center"}
            className="addSpaceBox cursor cardHover"
            padding={{
              base: "10px 18px",
              small: "10px",
              medium: "10px",
            }}
            width={"100%"}
            minHeight={{
              base: "auto",
              small: "auto",
              medium: "250px",
            }}
            borderRadius={"10px"}
            onClick={() => {
              setCurrentStage("instantQuote");
              resetContextVariables();
              navig("/quote");
            }}
            paddingLeft={{ medium: "0px", large: "50px", xl: "80px" }}
            gap={"10px"}
          >
            <Image
              className="cursor pointerEvents"
              alt="PermitZip logo"
              src={require("../Assets/addBtn.png")}
              height={{
                base: "50px",
                small: "115px",
                medium: "125px",
                large: "125px",
              }}
              aspectRatio={"1/1"}
              cursor={"--amplify-components-autocomplete-menu-option-cursor"}
            />
            <Heading
              level={variation}
              className="addFont"
              padding={{
                base: "10px 0",
                small: "10px 0",
                medium: "15px 0",
                large: "0 50px",
                xl: "0 50px",
              }}
            >
              New Instant Quote
            </Heading>
          </Card>
          {isAdmin && (
            <Card
              direction={{
                base: "row",
                small: "column",
                medium: "column",
                large: "row",
                xl: "row",
                xxl: "row",
              }}
              display={"flex"}
              justifyContent={{
                base: "flex-start",
                small: "center",
                medium: "center",
                large: "flex-start",
                xl: "flex-start",
                xxl: "flex-start",
              }}
              alignItems={"center"}
              className="addSpaceBox cursor cardHover"
              padding={{
                base: "10px 18px",
                small: "10px",
                medium: "10px",
              }}
              width={"100%"}
              minHeight={{
                base: "auto",
                small: "auto",
                medium: "250px",
              }}
              borderRadius={"10px"}
              onClick={() => {
                resetContextVariables();
                navigateTo("project");
              }}
              paddingLeft={{ medium: "0px", large: "50px", xl: "80px" }}
              gap={"10px"}
            >
              <Image
                className="cursor pointerEvents"
                alt="PermitZip logo"
                src={require("../Assets/addBtn.png")}
                aspectRatio={"1/1"}
                height={{
                  base: "50px",
                  small: "115px",
                  medium: "125px",
                  large: "125px",
                }}
              />
              <Heading
                level={variation}
                padding={{
                  base: "10px 0",
                  small: "10px 0",
                  medium: "15px 0",
                  large: "0 50px",
                  xl: "0 50px",
                }}
                className="addFont"
              >
                New Custom Project
              </Heading>
            </Card>
          )}
        </Flex>

        {route !== "authenticated" && (
          <Text
            fontSize={{
              base: "100%",
              small: "120%",
              medium: "140%",
            }}
            textAlign={"center"}
            marginTop={"20px"}
          >
            <Link
              onClick={() => {
                setIsLoggedIn(true);
                setGotToSignUp(false);
                navig("/login");
              }}
            >
              Register
            </Link>{" "}
            or{" "}
            <Link
              onClick={() => {
                setIsLoggedIn(true);
                setGotToSignUp(false);
                navig("/login");
              }}
            >
              Sign In
            </Link>{" "}
            to save, edit, and review your Quotes and Projects!
          </Text>
        )}
        {route === "authenticated" && (
          <ProjectCollection
            projectList={projectList}
            projectLoadCount={1000000}
            optionalFunction={viewProposals}
          />
        )}
      </Flex>
      {/* </Flex> */}
      <ModalComponent
        showModal={openViewProposals}
        onClose={() => {
          setIsMultiLayer(false);
          setOpenViewProposals(false);
        }}
        width={{
          base: "90%",
          small: "90%",
          medium: "auto",
          large: "auto",
        }}
        maxHeight="65%"
        maxWidth="auto"
        height="auto"
        children={
          <>
            <Flex
              direction={"column"}
              width={"100%"}
              padding={"10px 15px"}
              borderRadius={"10px"}
              border={"1px solid #c5c5c5"}
              overflow={"auto"}
              // minWidth={"300px"}
              minHeight={"300px"}
              gap={"15px"}
            >
              <Text
                fontSize={"130%"}
                fontWeight={"500"}
                textAlign={"center"}
                marginBottom={"5px"}
              >
                Proposal Record -{" "}
                {Object.values(proposals)[0]?.proposalData?.projectName?.value}
              </Text>
              <Text fontSize={"120%"} fontWeight={"500"}>
                {/* Project Name: {proposals[key]?.proposalData?.projectName?.value} */}
              </Text>
              <Table caption="" highlightOnHover={false} variation="bordered">
                <TableHead>
                  <TableRow>
                    <TableCell as="th">No.</TableCell>
                    <TableCell as="th">Date</TableCell>
                    <TableCell as="th">Primary Recipient</TableCell>
                    <TableCell as="th">Total Cost</TableCell>
                    <TableCell as="th">Proposal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortProposal.map((key, index) => {
                    return (
                      <TableRow>
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell>
                          {proposals[key]?.proposalData?.Date?.value}
                        </TableCell>
                        <TableCell>
                          {proposals[key]?.proposalData?.userEmail?.value}
                        </TableCell>
                        <TableCell>
                          <Text>
                            $
                            {formatNumberUS(
                              proposals[key]?.proposalData?.totalPrice?.value
                            )}
                          </Text>
                        </TableCell>
                        <TableCell>
                          <Button
                            width={"110px"}
                            onClick={() => {
                              console.log("show pdf", proposals[key]);
                              showProposal(proposals[key].proposalId);
                            }}
                          >
                            Open Pdf
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Flex>
          </>
        }
      />
    </Flex>
  );
};

export default HomePage;
