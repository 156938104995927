import { Auth, API, DataStore } from "aws-amplify";
import { Project } from "../models";

export const sendContactRequest = async (
  proposalData,
  requestDepartment,
  requestInfo,
  applicantName,
  applicantPhoneNumber
) => {
  let response;

  try {
    // Updating the userName to the proposal Data with Capitalizing the first letter
    proposalData["userName"]["value"] =
      applicantName.charAt(0).toUpperCase() + applicantName.slice(1);

    // Preparing payload for Slack webhook
    const payload = {
      proposalData: proposalData,
      requestDepartment: requestDepartment,
      requestInfo: requestInfo,
      applicantPhoneNumber: applicantPhoneNumber,
    };

    await API.post("gadgetApi", "/sendContactRequest", {
      body: payload,
    })
      .then((res) => {
        console.log(res);
        if (res.response.ok) {
          response = "success";
        } else {
          response = "failure";
        }
      })
      .catch((e) => {
        response = "failure";
      });
    console.log("Contact Request call", response);
  } catch (e) {
    response = "failure";
  }
  // console.log(response);

  return response;
};

export const verifyProjectInfo = async (
  projectId,
  navig,
  user,
  setOpenMissingFields,
  requiredFields,
  isProjectBuilder = false,
  isEmbedQuote = false
) => {
  // console.log(user, "user testing");
  // Attempt to fetch the project data using the provided projectId

  console.log(
    "isEditProject from gadgetfn",
    localStorage.getItem("isEditProject")
  );
  try {
    const project = await DataStore.query(Project, projectId);
    console.log(project, "verifyProjectInfo");
    console.log("requiredFields", requiredFields);
    // Check if there are any required fields that are missing
    if (
      Object.keys(requiredFields).filter(
        (field) => requiredFields[field] === true
      ).length > 0
    ) {
      // Alert the user to enter missing fields when the modal continue button was clicked
      if (isProjectBuilder) {
        alert("Please enter the missing fields to continue");
      } else {
        //open the modal to prompt the user to enter missing fields
        setOpenMissingFields(true);
      }
      return;
    }

    // Close the missing fields modal if all required fields are filled
    setOpenMissingFields(false);
    try {
      console.log(localStorage.getItem("guestFromIQ"), "guestFromIQ");
      if (!user) {
        // Open a new tab for guest user authentication and pass the projectId to the homePage which then redirects to the login page
        console.log("isEmbedQuote from gadgetFn", isEmbedQuote);
        if (isEmbedQuote) {
          let originUrl = window.location.origin;
          window.open(
            `${originUrl}/?guestUserAuthentication=${true}&projectId=${projectId}`
          );
        } else {
          DataStore.query(Project, projectId).then((res) => {
            console.log("res from guestFn", res);
            // Check if the project has editors and navigate to the project builder page when they tried to click the detailed estimate button from the IQ page once again, else go to login page
            localStorage.setItem("projectId", projectId);
            if (res.editors.length > 0) {
              localStorage.setItem("guestUserAuthentication", "true");
              navig("/");
            } else {
              localStorage.setItem("guestFromIQ", "guest");
              navig("/login");
            }
          });
        }
      } else {
        if (localStorage.getItem("guestFromIQ") === "hasUpdateProjectAccess") {
          localStorage.setItem("guestFromIQ", "canEditProject");
          navig("/");
        } else {
          if (localStorage.getItem("isEditProject") === "true") {
            localStorage.setItem("isEditProject", "false");
            navig("/");
          } else {
            if (isEmbedQuote) {
              springboardProject(projectId);
            } else {
              localStorage.setItem("springBoardProject", "true");
              localStorage.setItem("projectId", projectId);
              navig("/");
            }
          }
        }
      }
    } catch (error) {
      // Log any errors that occur during the authentication process
      console.log("Error in authentication: ", error);
    }
  } catch (error) {
    // Log any errors that occur while fetching the project data
    console.error("Error fetching project:", error);
  }
};

function springboardProject(projectId) {
  // const domainName = process.env.REACT_APP_HOSTING_URL;
  let originUrl = window.location.origin;
  // console.log(originUrl);
  window.open(
    `${originUrl}/?springBoardProject=${true}&projectId=${projectId}`
  );
}

let gadgetFunctions = {
  sendContactRequest,
  verifyProjectInfo,
};

export default gadgetFunctions;
