// React
import { createContext, useState } from "react";
import packagePriceTotal from "./InstantQuoteCalculator";
import ProjectCalculator from "./ProjectCalculator";
import { DataStore, Storage } from "aws-amplify";
import {
  SpaceTemplate,
  PromoCode,
  Proposal,
  Project,
  Organization,
} from "../models";
import isEmail from "validator/lib/isEmail";
import gadgetFunctions from "./GadgetFunctions";
import Swal from "sweetalert2";

// Context
const PzPrimeContext = createContext();

// Context Provider
const InstantQuoteProvider = (props) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const [isEmbedQuote, setIsEmbedQuote] = useState(false);

  const [isProjectSummaryOpen, setIsProjectSummaryOpen] = useState(false);

  const [isMultiLayer, setIsMultiLayer] = useState(false);

  const [colorMode, setColorMode] = useState("");

  const [currentStage, setCurrentStage] = useState("homePage");

  const [projectName, setProjectName] = useState("");

  const [authorizerName, setAuthorizerName] = useState("");

  const [authorizerEmail, setAuthorizerEmail] = useState("");

  const [selectedOrg, setSelectedOrg] = useState();

  const [isOrgSelected, setIsOrgSelected] = useState(false);

  const [address, setAddress] = useState("");

  const [spaceFlags, setSpaceFlags] = useState([]);

  const [selectedMarkets, setSelectedMarkets] = useState([]);

  const [filterMarkets, setFilterMarkets] = useState([]);

  const [changeInLocation, setChangeInLocation] = useState(false);

  const [buildingArea, setBuildingArea] = useState(0);

  const [projectArea, setProjectArea] = useState(0);

  const [isRequirementMet, setIsRequirementMet] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [numberOfBuildings, setNumberOfBuildings] = useState(1);

  const [activeStage, setActiveStage] = useState(0);

  const [projectId, setProjectId] = useState();

  const [spaceId, setSpaceId] = useState();

  const [changeInSpace, setChangeInSpace] = useState(false);

  const [changeInInput, setChangeInInput] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState("Collaborator");

  const [projectDescription, setProjectDescription] = useState("");

  const [constructionType, setConstructionType] = useState("New Construction");

  const [projectPriceSheets, setProjectPriceSheets] = useState();

  const [instantQuoteId, setInstantQuoteId] = useState("");

  const [user, setUser] = useState("");

  const [gotToSignUp, setGotToSignUp] = useState(true);

  const [configuredSpaces, setConfiguredSpaces] = useState();

  const [projectSpaces, setProjectSpaces] = useState({});

  const [changeInAdjustPricing, setChangeInAdjustPricing] = useState(false);

  const [openMissingFields, setOpenMissingFields] = useState(false);

  const [lastSentProposal, setLastSentProposal] = useState();

  const [buildingSquareFootage, setBuildingSquareFootage] = useState({
    brewery: 0,
    restaurant: 0,
    office: 0,
    warehouse: 0,
    multiFamily: 0,
    medical: 0,
    uniqueLayouts: 1,
  });

  const [discipline, setDiscipline] = useState({
    mechanical: 0,
    electrical: 0,
    plumbing: 0,
    // gas: 0,
  });

  const [userDetails, setUserDetails] = useState({
    name: "",
    phoneNumber: "",
    emailId: "",
  });

  const [organizationDetails, setOrganizationDetails] = useState({
    name: "",
    billingEmail: "",
    address: "",
    tagLine: "",
  });

  const [distanceMatrixData, setDistanceMatrixData] = useState({
    distance: 0,
    time: 0,
  });

  const [location, setLocation] = useState({
    lat: 37.538372,
    lng: -77.434011,
  });

  const stageValue = {
    projectDetails: 1,
    marketSelector: 2,
    spaceSelector: 3,
    // finalCheck: 4,
    // packageSelector: 5,
  };

  const [spaceImages, setSpaceImages] = useState({});

  const mapSvgIcon = (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.37892 10.2236L8 16L12.6211 10.2236C13.5137 9.10788 14 7.72154 14 6.29266V6C14 2.68629 11.3137 0 8 0C4.68629 0 2 2.68629 2 6V6.29266C2 7.72154 2.4863 9.10788 3.37892 10.2236ZM8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z"
        fill={colorMode === "dark" ? "#fff" : "#000"}
      />
    </svg>
  );

  const viewProposalSvgIcon = (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 17H15M9 13H15M9 9H10M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const editSvgIcon = (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m3.99 16.854-1.314 3.504a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L18.36 9.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L4.677 15.786a3 3 0 0 0-.687 1.068zm12.249-12.63 1.383-1.383c.248-.248.579-.406.925-.348.487.08 1.232.322 1.934 1.025.703.703.945 1.447 1.025 1.934.058.346-.1.677-.348.925L19.774 7.76s-.353-1.06-1.414-2.12c-1.06-1.062-2.121-1.415-2.121-1.415z"
        fill="#000000"
      />
    </svg>
  );

  const duplicateSvgIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" />
      <path
        d="M16 8V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V14C4 14.5304 4.21071 15.0391 4.58579 15.4142C4.96086 15.7893 5.46957 16 6 16H8M8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="-285.5"
        y="-382.5"
        width="524"
        height="817"
        rx="4.5"
        strokeDasharray="10 5"
      />
    </svg>
  );

  const deleteSvgIcon = (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 5H18M9 5V5C10.5769 3.16026 13.4231 3.16026 15 5V5M9 20H15C16.1046 20 17 19.1046 17 18V9C17 8.44772 16.5523 8 16 8H8C7.44772 8 7 8.44772 7 9V18C7 19.1046 7.89543 20 9 20Z"
        stroke="#000000"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  // Define a global configuration
  const swalWithDefaults = Swal.mixin({
    reverseButtons: true,
  });

  async function getImages(items) {
    let totalSpacesImage = {};
    for (const space of items) {
      let image = null;
      if (space.imageKey) {
        image = await Storage.get(`assets/spaceImages/${space.imageKey}`, {
          download: true,
          cacheControl: "no-cache",
        })
          .then((imageURL) => URL.createObjectURL(imageURL.Body))
          .catch((err) => {
            console.log(err);
            return null;
          });
      }
      // console.log(image);
      totalSpacesImage[space.id] = {
        imageURL: image ? image : require("../Assets/defaultSpaceImage.jpg"),
      };
    }
    setSpaceImages(totalSpacesImage);
  }

  function properNaming(field) {
    if (field) {
      const putSpace = field.split("_").join(" ");
      let name = "";
      for (const word of putSpace.split(" ")) {
        name +=
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + " ";
      }
      return name;
    } else return "";
  }

  const calculatePackagePrice = () => {
    return packagePriceTotal(
      discipline,
      buildingSquareFootage,
      constructionType
    );
  };

  //To validate the email
  const validateEmail = (emailAddress) => {
    return isEmail(emailAddress);
  };

  function fileNameSanitizer(fileName) {
    let sanitizedFileName = "";
    for (let letter of fileName) {
      if (letter == " ") sanitizedFileName += "_";
      else if (/^[a-zA-Z0-9!\-_'!()]$/.test(letter))
        sanitizedFileName += letter;
    }
    return sanitizedFileName;
  }

  function addCommaToPrice(price, decimals = 2) {
    // Deprecated function, do not implemnent. See formatNumberUS().
    return Number(price).toLocaleString("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }

  function formatNumberUS(number, decimals = 2) {
    // Adds comma's to numbers in US number system, and round to decimals.
    return Number(number).toLocaleString("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }

  // Funciton to show the dynamic contract on a new window or tab
  async function showPDF(pdfKey) {
    try {
      const encodedProposalPDF = await Storage.get(`${pdfKey}`, {
        cacheControl: "no-cache",
        contentType: "application/pdf",
      });
      console.log("encodedProposalPDF", encodedProposalPDF);
      window.open(encodedProposalPDF);
    } catch (e) {
      console.log("error", e);
      swalWithDefaults.fire({
        icon: "error",
        title: "Something went wrong!",
        text: "Could not load proposal.",
      });
    }
  }

  function decodePDF(encodedPDF) {
    var byteCharacters = atob(encodedPDF);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });
    console.log(file);
    return file;
  }

  function checkMinimumRequirements() {
    let errorMsg = "";
    let minimumRequirementsFlag = 1;
    if (projectName.length === 0) {
      errorMsg += "Project Name not entered \n";
      minimumRequirementsFlag = 0;
    }
    if (address.length === 0) {
      errorMsg += "Address not entered \n";
      minimumRequirementsFlag = 0;
    }
    // if (distanceMatrixData.distance === 0) {
    //   errorMsg += "This Address location is too far \n";
    //   minimumRequirementsFlag = 0;
    // }
    let tradeFlag = 0;
    for (const trade in discipline) {
      if (discipline[trade] === 1) {
        tradeFlag = 1;
        break;
      }
    }
    if (tradeFlag === 0) {
      errorMsg += "Minimum one Trade needed \n";
      minimumRequirementsFlag = 0;
    }
    if (projectArea === 0) {
      errorMsg += "Project Area not entered \n";
      minimumRequirementsFlag = 0;
    }
    if (numberOfBuildings < 1) {
      errorMsg += "Number Of Building should be greater than 0 \n";
      minimumRequirementsFlag = 0;
    }
    if (minimumRequirementsFlag === 0) return true;
    else {
      return false;
    }
  }
  async function getRequiredFieldStates(proId) {
    const project = await DataStore.query(Project, proId);
    let requiredFields = {
      projectName: false,
      address: false,
      discipline: false,
      projectArea: false,
      numberOfBuildings: false,
    };
    console.log(project, "proposalContext");
    if (project?.name.length === 0) {
      requiredFields = { ...requiredFields, projectName: true };
    }
    if (project?.location?.address?.length === 0) {
      requiredFields = { ...requiredFields, address: true };
    }
    let tradeFlag = 0;
    for (const trade in discipline) {
      if (discipline[trade] === 1) {
        tradeFlag = 1;
        break;
      }
    }
    if (project?.tradeSupervisions.length === 0) {
      requiredFields = { ...requiredFields, discipline: true };
    }
    if (project?.projectArea === 0) {
      requiredFields = { ...requiredFields, projectArea: true };
    }
    if (project?.numberOfBuildings < 1) {
      requiredFields = { ...requiredFields, numberOfBuildings: true };
    }
    return requiredFields;
  }

  function checkRequirements() {
    if (selectedMarkets && selectedMarkets.length > 0) return false;
    else return true;
  }

  function requirementChecker(navPage) {
    if (navPage === "/market-selector") {
      if (!checkMinimumRequirements())
        return [checkMinimumRequirements(), navPage];
      else return [checkMinimumRequirements(), "/project-details"];
    } else if (navPage === "/space-selector") {
      if (!checkMinimumRequirements()) {
        if (!checkRequirements()) return [checkRequirements(), navPage];
        else return [checkRequirements(), "/market-selector"];
      } else return [checkMinimumRequirements(), "/project-details"];
    } else if (navPage === "/final-checks") {
      if (!checkMinimumRequirements()) {
        if (!checkRequirements()) {
          if (isRequirementMet) return [!isRequirementMet, navPage];
          else return [!isRequirementMet, "/space-selector"];
        } else return [checkRequirements(), "/market-selector"];
      } else return [checkMinimumRequirements(), "/project-details"];
    }
  }

  function checkStage(toStage) {
    return stageValue[currentStage] >= stageValue[toStage];
  }

  async function getProposalData(templateProject) {
    const spaceTemplates = await DataStore.query(SpaceTemplate);

    const tradeConversion = {
      MECH: "mech",
      ELEC: "elec",
      PLUMB: "plum",
    };

    // Define proposal data structure
    let proposalData = {
      isInstantQuote: {
        description:
          "True if project was submitted through Instant Quote form, false if custom-built",
        dataType: "boolean",
      },
      creator: {
        hideReference: true,
        description: "The person who created this proposal",
        dataType: "text",
      },
      userName: {
        description:
          "Optional input - may be first and last, first only, or missing. Use email address as primary point of contact.",
        dataType: "text",
      },
      userEmail: {
        description: "User email",
        dataType: "text",
      },
      contactPhone: {
        description: "Primary contact number",
        dataType: "text",
      },
      address: {
        description: "Project street address",
        dataType: "text",
      },
      projectName: {
        description: "Name of the project",
        dataType: "text",
      },
      projectDescription: {
        hideReference: true,
        description: "Name of the project",
        dataType: "text",
        value: templateProject?.projectDescription,
      },
      totalPrice: {
        description: "Total price including taxes",
        dataType: "number",
      },
      mechCost: {
        description: "Total Cost for mechanical trade",
        dataType: "number",
      },
      elecCost: {
        description: "Total Cost for electrical trade",
        dataType: "number",
      },
      plumCost: {
        description: "Total Cost for plumbing trade",
        dataType: "number",
      },
      projectSubtotal: {
        description: "Subtotal (before promo codes)",
        dataType: "number",
      },
      promoCode: {
        description: "Promo code name",
        dataType: "text",
      },
      promoDiscount: {
        description: "Dollars discounted from initial subtotal",
        dataType: "number",
      },
      tax: {
        description: "Calculated tax",
        dataType: "number",
      },
      selectedTrades: {
        calculated: true,
        description:
          "Shows an inline, comma-separated list of selected trades on the project.",
        dataType: "text",
      },
      simpleSpaces: {
        calculated: true,
        dataType: "text",
        description:
          "Recommended to use on Instant Quote proposals instead of [projectSpaces] - shows SF per space, but does not show smaller area breakouts. Area breakdowns are automatically weighted and invisible to IQ users. Change [projectSpaces] metadata to edit.",
      },
      instantQuoteAssumptions: {
        calculated: true,
        dataType: "text",
        description:
          "Details the IQ area breakout assumptions. Includes data for all spaces currently on IQ, but will only render spaces included in project.",
        spaceLibrary: {},
      },
      projectSpaces: {
        calculated: true,
        description:
          "Shows more detail than simpleSpaces - includes sub-lists under each space for square footage per area",
        dataType: "text",
        spaces: {
          dataType: "valueList",
          default: {
            spaceType: "Space Title 1",
            areas: {
              dataType: "valueList",
              default: {
                name: "Area 1",
                SF: 1000,
              },
            },
          },
        },
      },
      ccAddresses: {
        hideReference: true,
        description: "",
        dataType: "text",
      },
      projectID: {
        hideReference: true,
        description: "",
        dataType: "text",
        value: templateProject.id,
      },
      Date: {
        hideReference: true,
        description: "",
        dataType: "text",
      },
    };

    const date = new Date();
    const d = date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
      .split(" ");

    proposalData.Date["value"] = `${d[1]} ${d[0]}, ${d[2]}`;

    // Get data from project
    if (templateProject.isInstantQuote)
      proposalData["isInstantQuote"]["value"] = templateProject.isInstantQuote;

    if (templateProject.userName)
      proposalData["userName"]["value"] = templateProject.userName;

    if (templateProject.userEmail)
      proposalData["userEmail"]["value"] = templateProject.userEmail;

    if (templateProject.userPhoneNumber)
      proposalData["contactPhone"]["value"] = templateProject.userPhoneNumber;

    if (templateProject.location.address)
      proposalData["address"]["value"] = templateProject.location.address;

    if (templateProject.name)
      proposalData["projectName"]["value"] = templateProject.name;

    if (templateProject.priceSheet.total)
      proposalData["totalPrice"]["value"] = parseFloat(
        templateProject.priceSheet.total
      );

    if (templateProject.priceSheet.initialSubtotal)
      proposalData["projectSubtotal"]["value"] = parseFloat(
        templateProject.priceSheet.initialSubtotal
      );

    if (templateProject.priceSheet.taxes)
      proposalData["tax"]["value"] = parseFloat(
        templateProject.priceSheet.taxes
      );

    for (let trade of Object.keys(tradeConversion)) {
      if (templateProject.tradeSupervisions.includes(trade))
        proposalData[tradeConversion[trade] + "Cost"]["value"] = parseFloat(
          templateProject.priceSheet[tradeConversion[trade] + "Total"]
        );
    }

    if (
      templateProject.promoCodes &&
      templateProject.promoCodes.length > 0 &&
      templateProject.promoCodes[0].code
    )
      proposalData["promoCode"]["value"] = templateProject.promoCodes[0].code;

    if (
      templateProject.priceSheet.promoDiscounts &&
      templateProject.priceSheet.promoDiscounts.length > 0 &&
      templateProject.priceSheet.promoDiscounts[0].discount
    )
      proposalData["promoDiscount"]["value"] =
        templateProject.priceSheet.promoDiscounts[0].discount;

    // For project spaces
    let spaceIndex = 0;
    for (const space in templateProject.spaceDict) {
      proposalData["projectSpaces"]["spaces"][spaceIndex] = {
        spaceType: space,
        areas: {
          dataType: "valueList",
        },
        path: `projectSpaces-spaces-${spaceIndex}`,
      };
      let areaIndex = 0;
      for (const area of templateProject.spaceDict[space].areas) {
        proposalData["projectSpaces"]["spaces"][spaceIndex]["areas"][
          areaIndex
        ] = {
          name: area.areaTitle,
          SF: Math.round(area.area),
          path: `projectSpaces-spaces-${spaceIndex}-areas-${areaIndex}`,
        };
        areaIndex++;
      }
      spaceIndex++;
    }

    // For IQ-Assumptions
    let iqSpaceIndex = 0;
    for (const space of spaceTemplates) {
      if (space.spaceTemplateInstantQuoteSpaceId) {
        proposalData.instantQuoteAssumptions.spaceLibrary[iqSpaceIndex] = {
          spaceType: space.templateName,
          areaAssumptions: {},
        };
        let iqAreaIndex = 0;
        for (const areaObj of space.areas) {
          proposalData.instantQuoteAssumptions.spaceLibrary[
            iqSpaceIndex
          ].areaAssumptions[iqAreaIndex] = {
            name: areaObj.areaTitle,
            weight: areaObj.instantQuoteWeight,
          };
          iqAreaIndex++;
        }
        iqSpaceIndex++;
      }
    }

    // Update calculated values
    return updateProposalData(proposalData);
  }

  // Calculate the .value field for calculate equal to true objects
  function updateProposalData(proposalData) {
    let enteredSpaces = [];

    // For selected trades
    let selectedTrades = [];
    let tradeConversion = {
      mechCost: "Mechanical (HVAC)",
      elecCost: "Electrical (Power & Lighting)",
      plumCost: "Plumbing (Water and Sanitary)",
    };
    for (const trade in tradeConversion) {
      if (
        proposalData[trade].hasOwnProperty("value") &&
        proposalData[trade].value > 0
      )
        selectedTrades.push(tradeConversion[trade]);
    }
    if (selectedTrades.length === 0) proposalData.selectedTrades["value"] = "";
    else if (selectedTrades.length === 1)
      proposalData.selectedTrades["value"] = selectedTrades[0];
    else if (selectedTrades.length === 2) {
      proposalData.selectedTrades["value"] =
        selectedTrades[0] + " and " + selectedTrades[1];
    } else if (selectedTrades.length > 2) {
      proposalData.selectedTrades["value"] = selectedTrades
        .slice(0, selectedTrades.length - 1)
        .join(", ");
      proposalData.selectedTrades["value"] +=
        ", and " + selectedTrades[selectedTrades.length - 1];
    }

    // For simple spaces
    let simpleSpaces = "<ul>";
    for (const space of Object.keys(proposalData.projectSpaces.spaces)) {
      if (!isNaN(space)) {
        proposalData.projectSpaces.spaces[space].totalSF = 0;
        enteredSpaces.push(proposalData.projectSpaces.spaces[space].spaceType);
        simpleSpaces +=
          "<li>" + proposalData.projectSpaces.spaces[space].spaceType + ": ";
        let totalSF = 0;
        for (const area of Object.keys(
          proposalData.projectSpaces.spaces[space].areas
        )) {
          if (!isNaN(area)) {
            totalSF += proposalData.projectSpaces.spaces[space].areas[area].SF;
          }
        }
        proposalData.projectSpaces.spaces[space].totalSF = totalSF;
        simpleSpaces += formatNumberUS(totalSF, 0) + " SF</li>";
      }
    }
    if (simpleSpaces !== "<ul>") simpleSpaces += "</ul>";
    else simpleSpaces = "";
    proposalData.simpleSpaces["value"] = simpleSpaces;

    // For project spaces
    let spaces = "<ul>";
    for (const space of Object.keys(proposalData.projectSpaces.spaces)) {
      if (!isNaN(space)) {
        spaces +=
          "<li>" +
          proposalData.projectSpaces.spaces[space].spaceType +
          `: ${formatNumberUS(
            proposalData.projectSpaces.spaces[space].totalSF,
            0
          )} SF<ul>`;
        for (const area of Object.keys(
          proposalData.projectSpaces.spaces[space].areas
        )) {
          if (!isNaN(area)) {
            spaces +=
              "<li>" +
              proposalData.projectSpaces.spaces[space].areas[area].name +
              ": " +
              formatNumberUS(
                proposalData.projectSpaces.spaces[space].areas[area].SF,
                0
              ) +
              " SF</li>";
          }
        }
        spaces += "</ul></li>";
      }
    }
    if (spaces !== "<ul>") spaces += "</ul>";
    else spaces = "";
    proposalData.projectSpaces["value"] = spaces;

    // For IQ-Assumptions
    let iqAssumptions = "<ul>";
    for (const space of Object.values(
      proposalData.instantQuoteAssumptions.spaceLibrary
    )) {
      if (enteredSpaces.includes(space.spaceType)) {
        iqAssumptions += "<li>" + space.spaceType + ":<ul>";
        for (const area of Object.values(space.areaAssumptions)) {
          iqAssumptions += "<li>" + area.name + ": " + area.weight + "%</li>";
        }
        iqAssumptions += "</ul></li>";
      }
    }
    if (iqAssumptions !== "<ul>") iqAssumptions += "</ul>";
    else iqAssumptions = "";
    proposalData.instantQuoteAssumptions["value"] = iqAssumptions;

    console.log("proposalData --> ", proposalData);

    return proposalData;
  }

  async function savePdfToS3(PDF, project_name) {
    let s3FileName = fileNameSanitizer(project_name);
    const date = new Date().toISOString();
    try {
      await Storage.put(`proposals/sent/${date}_${s3FileName}.pdf`, PDF, {
        contentType: "application/pdf",
      });
      return {
        msg: "success",
        key: `proposals/sent/${date}_${s3FileName}.pdf`,
      };
    } catch {
      return { msg: "error" };
    }
  }

  async function incrementPromoCodeUses(code) {
    const promoCodes = await DataStore.query(PromoCode, (promoCode) =>
      promoCode.code.eq(code)
    );
    if (promoCodes.length > 0) {
      let tempObj = JSON.parse(JSON.stringify(promoCodes[0]));
      if (tempObj.uses) tempObj.uses = parseInt(tempObj.uses) + 1;
      else tempObj.uses = 1;
      await DataStore.save(
        PromoCode.copyOf(promoCodes[0], (promoCodeCopy) => {
          promoCodeCopy.uses = tempObj.uses;
        })
      )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  }

  async function saveProposal(project, proposalData, pdfKey) {
    let result = false;
    let proposal = "";
    await DataStore.save(
      new Proposal({
        projectId: project ? project.id : null,
        proposalData: proposalData,
        pdfKey: pdfKey,
      })
    )
      .then((res) => {
        console.log(res);
        result = true;
        proposal = res;
      })
      .catch((err) => console.log(err));
    return [result, proposal];
  }

  async function updateProjectRecord(project, proposal, proposalData) {
    if (project) {
      let currentProject = await DataStore.query(Project, project.id);
      let today = new Date().toISOString();
      let tempObj = JSON.parse(JSON.stringify(currentProject));
      if (!tempObj.proposals) tempObj.proposals = {};
      tempObj.proposals[today] = {
        proposalId: proposal.id,
        proposalData: proposalData,
      };
      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy) => {
          projectCopy.proposals = tempObj.proposals;
        })
      )
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
  }

  async function navigateToProjectBuilder(project, option, navig) {
    const relation = [];
    // setProjectPriceSheets(project.priceSheet);
    ProjectCalculator(
      project.id,
      setProjectPriceSheets,
      setProjectSpaces,
      user
    );

    if (relation.length > 0) {
      const org = await DataStore.query(
        Organization,
        relation[0].organizationId
      );
      setIsOrgSelected(true);
      setSelectedOrg(org);
    }

    const constructionTypeConversion = {
      GROUND_UP: "New Construction",
      ADAPTIVE_REUSE_CLEAN_SLATE: "Adaptive Reuse/Clean Slate",
      ADAPTIVE_REUSE_RECYCLED_SYSTEMS: "Adaptive Reuse/Recycled System",
    };

    const tradeConversion = {
      MECH: "mechanical",
      ELEC: "electrical",
      PLUMB: "plumbing",
      GAS: "gas",
    };

    const marketConversion = {
      MULTIFAMILY_MIXED_USE: "Multifamily & Mixed Use",
      OFFICE_RETAIL: "Office and Retail",
      FOOD_BEVERAGE: "Food and Beverage",
      HEALTHCARE: "Healthcare",
      CORE_SHELL: "Core / Shell",
      WAREHOUSE_STORAGE: "Warehouse / Storage",
      SINGLE_FAMILY_RESIDENTIAL: "Single Family Residential",
      RELIGIOUS_CULTURAL: "Religious and Cultural",
      EDUCATION_DAYCARE: "Education and Daycare",
      FACTORY_INDUSTRIAL_GARAGE: "Factory | Industrial | Garage",
      THEATERS_EVENT_SPACES: "Theaters & Event Spaces",
      OTHER: "Other",
    };

    const packageLevelConversion = {
      MINIMALIST: "Minimalist",
      COLLABORATOR: "Collaborator",
      PERFECTIONIST: "Perfectionist",
    };

    for (let trade of project.tradeSupervisions)
      discipline[tradeConversion[trade]] = 1;

    let markets = [];
    for (const market of project.markets)
      markets.push(marketConversion[market]);

    setProjectId(project.id);
    setProjectName(project.name);
    setAddress(project.location.address);
    setLocation({
      lat: project.location.latitude,
      lng: project.location.longitude,
    });
    setDistanceMatrixData({
      distance: project.location.driveDistance,
      time: project.location.driveDuration,
    });
    setDiscipline(discipline);
    setConstructionType(constructionTypeConversion[project.constructionType]);
    setProjectArea(project.projectArea);
    setNumberOfBuildings(project.numberOfBuildings);
    setBuildingArea(project.totalBuildingsArea);
    setSelectedMarkets(markets);
    setFilterMarkets(markets);
    setSelectedPackage(packageLevelConversion[project.packageLevel]);
    setAuthorizerName(project.authorizerName);
    setAuthorizerEmail(project.authorizerEmail);
    setProjectSpaces(project.spaceDict);

    if (option !== "duplicate") {
      if (project.spaceDict && Object.keys(project.spaceDict).length > 0) {
        navig("/space-selector");
      } else {
        navig("/market-selector");
      }
    } else {
      navig("/project-details");
    }
  }

  function resetContextVariables() {
    setProjectId("");
    setProjectName("");
    setAddress("");
    setLocation({
      lat: 37.538372,
      lng: -77.434011,
    });
    setDistanceMatrixData({
      distance: 0,
      time: 0,
    });
    setDiscipline({ mechanical: 0, electrical: 0, plumbing: 0 });
    setConstructionType("New Construction");
    setProjectArea(0);
    setNumberOfBuildings(1);
    setBuildingArea(0);
    setSelectedMarkets([]);
    setFilterMarkets([]);
    setSelectedPackage("Collaborator");
    setAuthorizerName("");
    setAuthorizerEmail("");
    setProjectSpaces({});
  }

  const contextData = {
    isEmbedQuote,
    setIsEmbedQuote,
    isMultiLayer,
    setIsMultiLayer,
    getImages,
    mapSvgIcon,
    viewProposalSvgIcon,
    editSvgIcon,
    duplicateSvgIcon,
    deleteSvgIcon,
    isInfoOpen,
    setIsInfoOpen,
    isProjectSummaryOpen,
    setIsProjectSummaryOpen,
    isLoggedIn,
    setIsLoggedIn,
    projectSpaces,
    setProjectSpaces,
    isAdmin,
    ProjectCalculator,
    setIsAdmin,
    setSpaceImages,
    instantQuoteId,
    setInstantQuoteId,
    projectPriceSheets,
    colorMode,
    setColorMode,
    projectName,
    setProjectName,
    address,
    setAddress,
    distanceMatrixData,
    setDistanceMatrixData,
    location,
    setLocation,
    changeInLocation,
    setChangeInLocation,
    discipline,
    setDiscipline,
    addCommaToPrice,
    formatNumberUS,
    validateEmail,
    selectedOrg,
    setSelectedOrg,
    isOrgSelected,
    setIsOrgSelected,
    selectedMarkets,
    setSelectedMarkets,
    currentStage,
    setCurrentStage,
    selectedPackage,
    setSelectedPackage,
    buildingArea,
    setBuildingArea,
    projectArea,
    setProjectArea,
    numberOfBuildings,
    setNumberOfBuildings,
    constructionType,
    setConstructionType,
    changeInSpace,
    setChangeInSpace,
    activeStage,
    setActiveStage,
    checkStage,
    stageValue,
    spaceFlags,
    setSpaceFlags,
    spaceImages,
    changeInInput,
    setChangeInInput,
    projectId,
    setProjectId,
    spaceId,
    setSpaceId,
    authorizerName,
    setAuthorizerName,
    authorizerEmail,
    setAuthorizerEmail,
    filterMarkets,
    setFilterMarkets,
    organizationDetails,
    setOrganizationDetails,
    buildingSquareFootage,
    setBuildingSquareFootage,
    userDetails,
    setUserDetails,
    calculatePackagePrice,
    projectDescription,
    setProjectDescription,
    isRequirementMet,
    setIsRequirementMet,
    requirementChecker,
    user,
    setUser,
    configuredSpaces,
    setConfiguredSpaces,
    properNaming,
    setProjectPriceSheets,
    gotToSignUp,
    setGotToSignUp,
    getProposalData,
    updateProposalData,
    changeInAdjustPricing,
    setChangeInAdjustPricing,
    savePdfToS3,
    incrementPromoCodeUses,
    saveProposal,
    updateProjectRecord,
    showPDF,
    decodePDF,
    lastSentProposal,
    setLastSentProposal,
    gadgetFunctions,
    swalWithDefaults,
    navigateToProjectBuilder,
    openMissingFields,
    setOpenMissingFields,
    checkMinimumRequirements,
    getRequiredFieldStates,
    resetContextVariables,
  };

  return (
    <PzPrimeContext.Provider value={contextData}>
      {props.children}
    </PzPrimeContext.Provider>
  );
};

export { PzPrimeContext, InstantQuoteProvider };
