// Amplify
import { Button, Flex, Text, Heading, View, Icon } from "@aws-amplify/ui-react";
import { DataStore } from "aws-amplify";
import {
  Project,
  ProjectLocation,
  PriceSheet,
  PricingModel,
  BasePrice,
} from "../../models";

// Gadget Components
import {
  MarketSelector,
  ModalComponent,
  ProjectDetails,
  SpaceSelector,
  SpaceTemplateFormView,
} from "..";
import {
  ProgressBar,
  ProjectSummary,
  Header,
  HomePage,
} from "../../GadgetComponents";

import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useContext, useEffect } from "react";
import { PzPrimeContext } from "../../Context";
import AdjustPricingForm from "../AdjustPricingForm";
import CostFlagTemplateForm from "../CostFlagTemplateForm";
import DynamicQuoteForm from "../InstantQuoteForm/DynamicQuoteForm";
import QuoteFormEmbed from "../InstantQuoteForm/QuoteFormEmbed";
import PromoCodeForm from "../PromoCodeFormView";

const MainBodyGridItem = (props) => {
  const { ...rest } = props;
  const navig = useNavigate();

  const {
    currentStage,
    changeInInput,
    projectId,
    setProjectId,
    projectName,
    projectArea,
    numberOfBuildings,
    buildingArea,
    selectedMarkets,
    constructionType,
    discipline,
    location,
    distanceMatrixData,
    address,
    selectedPackage,
    userDetails,
    user,
    viewProposalSvgIcon,
    editSvgIcon,
    duplicateSvgIcon,
    deleteSvgIcon,
    isInfoOpen,
    setIsInfoOpen,
    isProjectSummaryOpen,
    setIsProjectSummaryOpen,
    openMissingFields,
    setOpenMissingFields,
    gadgetFunctions,
    getRequiredFieldStates,
    colorMode,
    setIsMultiLayer,
    isEmbedQuote,
  } = useContext(PzPrimeContext);

  const pathLocation = useLocation();

  const { pathname } = pathLocation;

  async function saveProject() {
    let projectBaseFees = {};
    let baseFeesRecords = await DataStore.query(BasePrice);
    if (baseFeesRecords.length > 0) {
      baseFeesRecords.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      let trades = ["mech", "elec", "plum"];
      for (let trade of trades) {
        projectBaseFees[trade] = [
          baseFeesRecords[0]["disciplines"][trade]["setUpFee"],
          baseFeesRecords[0]["disciplines"][trade]["costPerSF"],
        ];
      }
    }

    const constructionTypeConversion = {
      "New Construction": "GROUND_UP",
      "Adaptive Reuse/Clean Slate": "ADAPTIVE_REUSE_CLEAN_SLATE",
      "Adaptive Reuse/Recycled System": "ADAPTIVE_REUSE_RECYCLED_SYSTEMS",
    };

    const marketConversion = {
      "Multifamily & Mixed Use": "MULTIFAMILY_MIXED_USE",
      "Office and Retail": "OFFICE_RETAIL",
      "Food and Beverage": "FOOD_BEVERAGE",
      Healthcare: "HEALTHCARE",
      "Core / Shell": "CORE_SHELL",
      "Warehouse / Storage": "WAREHOUSE_STORAGE",
      "Single Family Residential": "SINGLE_FAMILY_RESIDENTIAL",
      "Religious and Cultural": "RELIGIOUS_CULTURAL",
      "Education and Daycare": "EDUCATION_DAYCARE",
      "Factory | Industrial | Garage": "FACTORY_INDUSTRIAL_GARAGE",
      "Theaters & Event Spaces": "THEATERS_EVENT_SPACES",
      Other: "OTHER",
    };

    const tradeConversion = {
      mechanical: "MECH",
      electrical: "ELEC",
      plumbing: "PLUMB",
      gas: "GAS",
    };

    const packageLevelConversion = {
      Minimalist: "MINIMALIST",
      Collaborator: "COLLABORATOR",
      Perfectionist: "PERFECTIONIST",
    };

    let trades = [];
    for (const trade in discipline) {
      if (discipline[trade] === 1) trades.push(tradeConversion[trade]);
    }

    let markets = [];
    for (const market of selectedMarkets) {
      markets.push(marketConversion[market]);
    }

    if (projectId) {
      const currentProject = await DataStore.query(Project, projectId);
      // let editors;
      // if (user && !currentProject.editors) editors = [user];

      await DataStore.save(
        Project.copyOf(currentProject, (projectCopy) => {
          projectCopy.name = projectName;
          projectCopy.projectArea = projectArea;
          projectCopy.numberOfBuildings = numberOfBuildings;
          projectCopy.totalBuildingsArea = buildingArea;
          projectCopy.userEmail = userDetails.emailId
            ? userDetails.emailId
            : null;
          projectCopy.markets = markets;
          projectCopy.constructionType =
            constructionTypeConversion[constructionType];
          projectCopy.tradeSupervisions = trades;
          projectCopy.location = new ProjectLocation({
            latitude: location.lat,
            longitude: location.lng,
            address: address,
            driveDistance: distanceMatrixData.distance,
            driveDuration: distanceMatrixData.time,
          });
          projectCopy.packageLevel = packageLevelConversion[selectedPackage];
          // projectCopy.editors = editors;
        })
      )
        .then((res) => {})
        .catch((err) => console.log(err));
    } else {
      if (projectName)
        await DataStore.save(
          new Project({
            name: projectName,
            projectArea: projectArea,
            numberOfBuildings: numberOfBuildings,
            totalBuildingsArea: buildingArea,
            markets: selectedMarkets,
            userEmail: userDetails.emailId ? userDetails.emailId : null,
            constructionType: constructionTypeConversion[constructionType],
            tradeSupervisions: trades,
            location: new ProjectLocation({
              latitude: location.lat,
              longitude: location.lng,
              address: address,
              driveDistance: distanceMatrixData.distance,
              driveDuration: distanceMatrixData.time,
            }),
            packageLevel: packageLevelConversion[selectedPackage],
            editors: user ? [user] : [],
            priceSheet: new PriceSheet({
              pricingModel: PricingModel.PROJECT_BASE_FEES,
              mech: projectBaseFees["mech"],
              elec: projectBaseFees["elec"],
              plum: projectBaseFees["plum"],
            }),
            promoCodes: [],
          })
        )
          .then((res) => {
            setProjectId(res.id);
          })
          .catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    saveProject();
  }, [changeInInput]);

  const Css = `
  .homeAndAdminPageLayer{
    position: relative;
    z-index: 2;
  }
    .mainScrollBar::-webkit-scrollbar {
      width: 15px;               /* width of the scrollbar */
    background-color: transparent;
  }
  .mainScrollBar::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  .mainScrollBar::-webkit-scrollbar-thumb {
    background-color: #b0b3b5;    /* color of the scroll thumb */
    background-clip: content-box;
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 2px solid transparent;  /* creates padding around scroll thumb */
  }
  .mainScrollBar::-webkit-scrollbar-track-piece:end {
    background: inherit;
    margin-bottom:0px; 
}

.mainScrollBar::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 0px;
}
  .mainScrollBar{
    z-index: 1;
  }
  .headerLayer{
    z-index:3;
  }
  .headerModal{
    z-index: 4;
  }

  @media screen and (max-width: 768px) {
    .mainScrollBar::-webkit-scrollbar {
      width: 0px;
      background-color: inherit;
    }
    .mainScrollBar::-webkit-scrollbar-track-piece:end {
      background: inherit;
      margin-bottom:20px; 
  }
  
  .mainScrollBar::-webkit-scrollbar-track-piece:start {
      background: transparent;
      margin-top: 50px;
  }
}
  `;
  async function handleModalOnclick() {
    const project = await DataStore.query(Project, projectId);
    let isProjectBuilder = true;
    let requiredFields = await getRequiredFieldStates(projectId);
    let projectObj = {};
    const tradeConversion = {
      mechanical: "MECH",
      electrical: "ELEC",
      plumbing: "PLUMB",
      gas: "GAS",
    };
    let projectAddress = new ProjectLocation({
      latitude: location.lat,
      longitude: location.lng,
      address: address,
      driveDistance: distanceMatrixData.distance,
      driveDuration: distanceMatrixData.time,
    });
    let trades = [];
    for (const trade in discipline) {
      if (discipline[trade] === 1) trades.push(tradeConversion[trade]);
    }
    let reqFieldConversion = {
      projectName: ["name", projectName],
      address: ["location", projectAddress],
      discipline: ["tradeSupervisions", trades],
      projectArea: ["projectArea", projectArea],
      numberOfBuildings: ["numberOfBuildings", numberOfBuildings],
    };
    for (let field in requiredFields) {
      if (requiredFields[field] === true) {
        projectObj[reqFieldConversion[field][0]] = reqFieldConversion[field][1];
      }
    }
    console.log(projectObj, "projectObj");
    await DataStore.save(
      Project.copyOf(project, (projectCopy) => {
        for (let field in projectObj) {
          projectCopy[field] = projectObj[field];
        }
      })
    )
      .then(async (res) => {
        console.log(res, "res");
        let requiredFields = await getRequiredFieldStates(projectId);
        gadgetFunctions.verifyProjectInfo(
          projectId,
          navig,
          user,
          setOpenMissingFields,
          requiredFields,
          isProjectBuilder,
          isEmbedQuote
        );
      })
      .catch((err) => console.log(err));
  }

  return (
    <Flex width={"100%"} direction={"column"} gap={"0"}>
      <style>{Css}</style>
      {pathname !== "/embed-quote" && (
        <Header position="sticky" top="0px" className="headerLayer" />
      )}

      <Flex
        width={"100%"}
        className="homeAndAdminPageLayer"
        direction={"column"}
      >
        <Routes>
          <Route path="/" element={<HomePage />} />

          {/* Dynamic Quote Form */}
          <Route path="/quote" element={<DynamicQuoteForm />} />

          {/* Wix View Form */}
          <Route path="/embed-quote" element={<QuoteFormEmbed />} />

          {/* Admin Options */}
          <Route path="/pricing" element={<AdjustPricingForm />} />
          <Route path="/space-templates" element={<SpaceTemplateFormView />} />
          <Route path="/promo-codes" element={<PromoCodeForm />} />
          <Route path="/cost-flags" element={<CostFlagTemplateForm />} />
        </Routes>
        {/* <View className="invisibleBlockIq"></View> */}
        <ModalComponent
          showModal={openMissingFields}
          onClose={() => setOpenMissingFields(false)}
          width={{
            base: "96%",
            small: "85%",
            medium: "70%",
            large: "50%",
          }}
          maxHeight="75%"
          // maxWidth="auto"
          position={pathname === "/embed-quote" ? "bottom" : "center"}
          height="auto"
          children={
            <Flex direction={"column"} overflow={"auto"}>
              <Text
                textAlign={"center"}
                fontSize={{
                  base: "16px",
                  small: "18px",
                  medium: "20px",
                  large: "22px",
                }}
                fontWeight={"bold"}
                position={"sticky"}
                marginTop={"-10px"}
                top={"-3px"}
                className="headerModal"
                backgroundColor={colorMode === "dark" ? "#191f21" : "#fff"}
                padding={"8px"}
              >
                Add some missing info to continue
              </Text>
              {/* <Flex
                direction={"column"}
                // backgroundColor={colorMode === "dark" && "#2e3436"}
                width={"100%"}
                borderRadius="10px"
                gap={"20px"}
              > */}
              <ProjectDetails calledFromModal={true} />
              {/* </Flex> */}
              <Button
                display={"flex"}
                direction={"column"}
                width="100%"
                backgroundColor={"#40AABF"}
                color={"#fff"}
                position={"sticky"}
                bottom={"0px"}
                marginTop={"10px"}
                onClick={async () => {
                  handleModalOnclick();
                }}
              >
                <Text
                  fontSize={{
                    base: "14px",
                    small: "14px",
                    medium: "16px",
                    large: "16px",
                  }}
                >
                  Continue to Project Builder
                </Text>
                <Text
                  display={!user ? "block" : "none"}
                  fontSize={{
                    base: "12px",
                    small: "12px",
                    medium: "14px",
                    large: "14px",
                  }}
                >
                  (Requires Account Signup)
                </Text>
              </Button>
            </Flex>
          }
        />
      </Flex>
      <Flex
        width={"100%"}
        gap={"0"}
        top={{
          base: "90px",
          small: "110px",
          medium: "121px",
          large: "121px",
        }}
        position={"fixed"}
        height={{
          base:
            currentStage === "spaceSelector"
              ? "calc(100% - 90px)"
              : "calc(100% - 120px)",
          small: "calc(100% - 110px)",
          medium: "calc(100% - 121px)",
          large: "calc(100% - 121px)",
        }}
      >
        <Flex
          direction={"column"}
          width={"100%"}
          gap={"0"}
          position={"relative"}
          height={"100%"}
        >
          {currentStage !== "homePage" && currentStage !== "instantQuote" && (
            <ProgressBar
              display={{
                base: "none",
                small: "none",
                medium: "flex",
                large: "flex",
              }}
              position="sticky"
              left="0px"
              top={"0px"}
              width="100%"
            />
          )}
          <Flex
            height={"100%"}
            border="50px"
            overflow={"auto"}
            width={"100%"}
            className="mainScrollBar"
            direction={"column"}
          >
            <Routes>
              {/* Detailed Estimate routes */}
              <Route
                path="/project-details"
                element={<ProjectDetails calledFromModal={false} />}
              />
              <Route path="/market-selector" element={<MarketSelector />} />
              <Route path="/space-selector" element={<SpaceSelector />} />
            </Routes>
            <View className="invisibleBlock"></View>
          </Flex>
          <ModalComponent
            display={{
              base: "flex",
              small: "flex",
              medium: "flex",
              large: "none",
            }}
            showModal={isInfoOpen}
            onClose={() => setIsInfoOpen(false)}
            children={
              <Flex direction={"column"} gap={"10px"} padding={"15px 10px"}>
                {currentStage === "homePage" && (
                  <Flex alignItems={"center"}>
                    <Icon
                      fontSize={"34px"}
                      borderRadius={"20px"}
                      padding={"3px"}
                      backgroundColor="#bcecf5"
                    >
                      {viewProposalSvgIcon}
                    </Icon>
                    <Text> View Proposals</Text>
                  </Flex>
                )}
                <Flex alignItems={"center"}>
                  <Icon
                    fontSize={"34px"}
                    borderRadius={"20px"}
                    padding={"3px"}
                    backgroundColor="#bcecf5"
                  >
                    {editSvgIcon}
                  </Icon>
                  <Text> Edit</Text>
                </Flex>
                <Flex alignItems={"center"}>
                  <Icon
                    fontSize={"34px"}
                    borderRadius={"20px"}
                    padding={"3px"}
                    backgroundColor="#bcecf5"
                  >
                    {duplicateSvgIcon}
                  </Icon>
                  <Text> Duplicate</Text>
                </Flex>
                <Flex alignItems={"center"}>
                  <Icon
                    fontSize={"34px"}
                    borderRadius={"20px"}
                    padding={"3px"}
                    backgroundColor="#bf4040"
                  >
                    {deleteSvgIcon}
                  </Icon>
                  <Text>Delete</Text>
                </Flex>
              </Flex>
            }
          />
          <ModalComponent
            display={{
              base: "flex",
              small: "flex",
              medium: "flex",
              large: "none",
            }}
            zIndex={5}
            showModal={isProjectSummaryOpen}
            onClose={() => {
              setIsProjectSummaryOpen(false);
            }}
            maxWidth="450px"
            maxHeight="85%"
            width="90%"
            children={<ProjectSummary />}
          />
        </Flex>

        {currentStage !== "projectDetails" &&
          currentStage !== "homePage" &&
          currentStage !== "instantQuote" && (
            <ProjectSummary
              display={{
                base: "none",
                small: "none",
                medium: "none",
                large: "flex",
              }}
              minWidth="400px"
              maxWidth="450px"
              top="121px"
              right="0px"
            />
          )}
      </Flex>

      <Outlet />
    </Flex>
  );
};

export default MainBodyGridItem;
