import {
  CheckboxField,
  Flex,
  Heading,
  Text,
  View,
  Icon,
  Button,
  Divider,
} from "@aws-amplify/ui-react";
import { useState, useContext, useEffect, useRef } from "react";
import SpaceSelectorForm from "./SpaceSelectorForm";
import { PzPrimeContext } from "../Context/ProposalContext";
import { SpaceSelectorOverview } from ".";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { DataStore, Storage } from "aws-amplify";
import {
  SpaceTemplate,
  Space,
  CostFlagTemplate,
  SpaceCostFlagRelationships,
} from "../models";
import { useNavigate } from "react-router-dom";
import ProjectBuilderNavHeader from "./ProjectBuilderNavHeader";

const SpaceSelector = (props) => {
  const navig = useNavigate();

  const {
    colorMode,
    spaceImages,
    changeInSpace,
    projectId,
    setSpaceId,
    filterMarkets,
    requirementChecker,
    testPricing,
    setFilterMarkets,
    setCurrentStage,
    setActiveStage,
    stageValue,
    currentStage,
    setSpaceImages,
    projectSpaces,
    setProjectSpaces,
    getImages,
    editSvgIcon,
  } = useContext(PzPrimeContext);

  const [selectedSpace, setSelectedSpace] = useState("");

  const [mode, setMode] = useState("");

  const [addNewSpace, setAddNewSpace] = useState(false);

  const [toggleFilter, setToggleFilter] = useState(false);

  const [tempSpaceInputs, setTempSpaceInputs] = useState({});

  const [spaceTemplates, setSpaceTemplates] = useState([]);

  const [spaces, setSpaces] = useState([]);

  const [costFlags, setCostFlags] = useState([]);

  const markets = {
    "Multifamily & Mixed Use": "MULTIFAMILY_MIXED_USE",
    "Office and Retail": "OFFICE_RETAIL",
    "Food and Beverage": "FOOD_BEVERAGE",
    Healthcare: "HEALTHCARE",
    "Core / Shell": "CORE_SHELL",
    "Warehouse / Storage": "WAREHOUSE_STORAGE",
    "Single Family Residential": "SINGLE_FAMILY_RESIDENTIAL",
    "Religious and Cultural": "RELIGIOUS_CULTURAL",
    "Education and Daycare": "EDUCATION_DAYCARE",
    "Factory | Industrial | Garage": "FACTORY_INDUSTRIAL_GARAGE",
    "Theaters & Event Spaces": "THEATERS_EVENT_SPACES",
    Other: "OTHER",
  };

  async function getSpaces() {
    DataStore.observeQuery(Space, (space) =>
      space.projectSpacesId.eq(projectId)
    ).subscribe(({ items }) => {
      setSpaces(items);
    });
  }

  async function getTemplates() {
    DataStore.observeQuery(SpaceTemplate).subscribe(({ items }) => {
      setSpaceTemplates(items);
      getImages(items);
    });
    DataStore.observeQuery(CostFlagTemplate).subscribe(({ items }) => {
      setCostFlags(items);
    });
  }

  const filterRef = useRef(null);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setToggleFilter(false);
    }
  };

  useEffect(() => {
    if (toggleFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleFilter]);

  useEffect(() => {
    // if(currentStage === 'spaceSelector')
    getTemplates();
  }, []);

  useEffect(() => {
    // if(currentStage === 'spaceSelector')
    getSpaces();
  }, [changeInSpace]);

  const customCss = `
  .cursor{
      cursor:pointer;
  } 
  .pointerEvents{
    pointer-events: none; 
    user-select:none;
  }
  .hover:hover{
    -webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.75);
    transition: 0.2s ease-in;
  }
  .outline{
    outline: 1px solid rgba(191, 191, 191, 0.80);
  }
  .zIndexSpaceSelector{
    z-index: 4;
    transition: 0.5s;
  }
  .toggleCloseBtn{
    position:absolute;
    top:15px;
    right:10px;
    background:#fff;
    transition:0.5s ease;
    cursor:pointer;
  
  }
  .toggleCloseBtn:hover{
    background:lightblue;
  }
  `;

  if (requirementChecker("/space-selector")[0])
    navig(requirementChecker("/space-selector")[1]);

  setCurrentStage("spaceSelector");
  setActiveStage(stageValue[currentStage] - 1);

  async function checkSelectedSpace(space) {
    let name;
    if (projectSpaces && Object.keys(projectSpaces).length > 0) {
      let spaceFilter = Object.keys(projectSpaces).map((spaceInput) => {
        if (projectSpaces[spaceInput]["templateID"] === space.id)
          return projectSpaces[spaceInput]["customName"];
      });
      if (spaceFilter.length > 0) {
        let i = 1;
        let nameFlag = false;
        while (!nameFlag) {
          let tempName;
          if (i === 1) tempName = space.templateName;
          else tempName = space.templateName + "-" + i;
          if (!spaceFilter.includes(tempName)) {
            name = tempName;
            nameFlag = true;
          }
          i++;
        }
      } else name = space.templateName;
    } else {
      name = space.templateName;
    }
    let newSpace = {
      customName: name,
      className: space.templateName,
      templateID: space.id,
      areas: [],
      auxInputs: [],
      costFlags: [],
      priceSheet: space.priceSheet,
    };
    for (const input of space.areas) {
      let spaceInputField = {
        areaTitle: input.areaTitle,
        area: 0,
        priceSheet: input.priceSheet,
      };
      newSpace["areas"].push(spaceInputField);
    }
    for (const input of space.auxInputs) {
      let spaceAuxInputField = {
        inputTitle: input.inputTitle,
        dataType: input.dataType,
      };
      if (input.dataType === "INTEGER") spaceAuxInputField["intValue"] = 0;
      else spaceAuxInputField["checkedValue"] = false;
      newSpace["auxInputs"].push(spaceAuxInputField);
    }
    const spaceFlags = await DataStore.query(
      SpaceCostFlagRelationships,
      (relation) => relation.spaceTemplateId.eq(space.id)
    );
    for (const input of spaceFlags) {
      const flag = await DataStore.query(
        CostFlagTemplate,
        input.costFlagTemplateId
      );
      let spaceFlags = {
        featureTitle: flag.featureTitle,
        checkedValue: false,
        alertLevel: flag.alertLevel,
        alertTitle: flag.alertTitle,
        alertBody: flag.alertBody,
      };
      newSpace["costFlags"].push(spaceFlags);
    }
    setTempSpaceInputs(newSpace);
    setMode("create");
    setSelectedSpace(newSpace.customName);
  }

  function SpaceCard(space) {
    console.log(space, "space");
    console.log(spaceImages, "spaceImages");
    return (
      <Flex
        backgroundColor={colorMode === "dark" && "#2e3436"}
        direction={"column"}
        alignItems={"center"}
        padding={{
          base: "10px",
          small: "10px",
          medium: "15px",
        }}
        borderRadius={"10px"}
        width={{
          base: "150px",
          small: "150px",
          medium: "320px",
          large: "320px",
        }}
        // minWidth={"300px"}
        border={"1px solid #c5c5c5"}
        className={"cursor hover"}
        gap={"10px"}
        onClick={() => checkSelectedSpace(space.space)}
      >
        <View
          border={"1px solid #c5c5c5"}
          borderRadius={"10px"}
          overflow={"hidden"}
          className="pointerEvents"
          height={{
            base: "100px",
            small: "100px",
            medium: "205px",
            large: "205px",
          }}
          width={"100%"}
        >
          <LazyLoadImage
            src={spaceImages[space.space.id]?.imageURL}
            alt={space.space.templateName}
            placeholderSrc={require("../Assets/defaultSpaceImage.jpg")}
            height="100%"
            width="100%"
            className="pointerEvents"
            effect="blur"
            style={{
              objectFit: "cover",
              border: "1px solid #c5c5c5",
              borderRadius: "10px",
            }}
          />
        </View>
        <Text
          className={"cursor pointerEvents"}
          margin={{
            base: "5px",
            small: "5px",
            medium: "8px",
          }}
          marginBottom={{
            base: "5px",
            small: "5px",
            medium: "15px",
          }}
          textAlign={"center"}
          fontSize={{
            base: "15px",
            small: "15px",
            medium: "25px",
            large: "25px",
          }}
          fontStyle="normal"
          fontWeight="400"
          lineHeight="130%"
        >
          {space.space.templateName}
        </Text>
      </Flex>
    );
  }

  function changeFilter(e) {
    let sample = filterMarkets;
    if (!e.target.checked)
      sample = sample.filter((market) => market !== e.target.value);
    else sample = [...sample, e.target.value];
    setFilterMarkets(sample);
  }

  return (
    <Flex direction={"column"} width={"100%"} gap={"0"}>
      <style>{customCss}</style>
      {!selectedSpace ? (
        projectSpaces &&
        Object.keys(projectSpaces).length > 0 &&
        !addNewSpace ? (
          <SpaceSelectorOverview
            selectedSpace={selectedSpace}
            setSelectedSpace={setSelectedSpace}
            addNewSpace={addNewSpace}
            setAddNewSpace={setAddNewSpace}
            mode={mode}
            setMode={setMode}
            tempSpaceInputs={tempSpaceInputs}
            setTempSpaceInputs={setTempSpaceInputs}
            spaces={spaces}
          />
        ) : (
          <>
            {" "}
            {/* Space selector page alson with filter by markets feature */}
            <ProjectBuilderNavHeader
              backArrowFunction={() => {
                if (
                  addNewSpace &&
                  projectSpaces &&
                  Object.keys(projectSpaces).length > 0
                )
                  setAddNewSpace(false);
                else navig("/market-selector");
              }}
              title={
                projectSpaces && Object.keys(projectSpaces).length > 0
                  ? "Add Another Space to your Project"
                  : "Add the first Space to your Project"
              }
              display={"flex"}
              position={{
                base: "fixed",
                small: "fixed",
                medium: "sticky",
                large: "sticky",
              }}
              top={{
                base: "90px",
                small: "110px",
                medium: "0",
                large: "0",
              }}
              width={"100%"}
              height={{
                base: "50px",
                small: "50px",
                medium: "60px",
                large: "60px",
              }}
              padding={"10px 5px"}
              style={{
                zIndex: "9",
                // borderTop: "1px solid #c5c5c5",
                borderBottom: "1px solid #c5c5c5",
              }}
            />
            <Flex
              width={"100%"}
              marginTop={{
                base: "50px",
                small: "50px",
                medium: "0",
                large: "0",
              }}
              height={"100%"}
            >
              <View
                ref={filterRef}
                width={"100%"}
                maxWidth={"300px"}
                margin={"0px"}
                position={"absolute"}
                padding={"20px"}
                // top={"120px"}
                className="zIndexSpaceSelector"
                left={{
                  base: toggleFilter ? "0px" : "-300px",
                  small: toggleFilter ? "0px" : "-300px",
                  medium: "0px",
                  large: "0px",
                }}
                backgroundColor={{
                  base: colorMode === "dark" ? "#2e3436" : "#f4f4f4",
                  small: colorMode === "dark" ? "#2e3436" : "#f4f4f4",
                  medium: colorMode === "dark" ? "#191f21" : "#fff",
                }}
                // backgroundColor={"GrayText"}
              >
                <Icon
                  display={{
                    base: "block",
                    small: "block",
                    medium: "none",
                    large: "none",
                  }}
                  className="toggleCloseBtn"
                  borderRadius={"50%"}
                  padding={"2px"}
                  paddingLeft={"1px"}
                  fontSize={"25px"}
                  textAlign={"center"}
                  onClick={() => setToggleFilter(false)}
                >
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                    fill="#000"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
                  </svg>
                </Icon>
                <Heading>Filter By Markets</Heading>
                <Flex gap={"10px"} padding={"15px"} direction={"column"}>
                  {Object.keys(markets).map((market, index) => (
                    <CheckboxField
                      key={index}
                      label={market}
                      value={market}
                      onClick={(e) => changeFilter(e)}
                      checked={filterMarkets.includes(market)}
                    />
                  ))}
                </Flex>
              </View>
              <Flex
                width={"100%"}
                wrap={"wrap"}
                margin={{
                  base: "10px",
                  small: "20px",
                  medium: "10px 20px 10px 350px",
                  large: "10px 20px 10px 350px",
                }}
                justifyContent={"flex-start"}
                direction={"column"}
                style={{
                  filter: toggleFilter ? "blur(5px)" : "none",
                }}
              >
                <Flex direction={"column"} width={"100%"}>
                  <Text
                    fontSize={{
                      base: "18px",
                      small: "22px",
                      medium: "25px",
                    }}
                    fontWeight="500"
                  >
                    Spaces in Selected Markets:
                  </Text>
                  <Flex
                    alignItems={"center"}
                    padding={"5px 8px"}
                    backgroundColor={
                      colorMode === "dark" ? "#2e3436" : "#c5c5c5"
                    }
                    borderRadius={"25px"}
                    border={"1px solid #c5c5c5"}
                    width={"fit-content"}
                    onClick={() => setToggleFilter(!toggleFilter)}
                    display={{
                      base: "flex",
                      small: "flex",
                      medium: "none",
                      large: "none",
                    }}
                    gap={"8px"}
                  >
                    <Icon
                      fontSize={"28px"}
                      borderRadius={"20px"}
                      padding={"3px"}
                      backgroundColor="#bcecf5"
                    >
                      {editSvgIcon}
                    </Icon>
                    <Text>Adjust Market Filters</Text>
                  </Flex>
                  <Flex
                    wrap={"wrap"}
                    gap={{
                      base: "5px",
                      small: "15px",
                    }}
                  >
                    {spaceTemplates.map((item, index) => {
                      if (filterMarkets.length === 0)
                        return <SpaceCard key={index} space={item} />;
                      else {
                        let isPresent = false;
                        for (const market of filterMarkets) {
                          if (item.markets.includes(markets[market])) {
                            isPresent = true;
                            break;
                          }
                        }
                        if (isPresent)
                          return <SpaceCard key={index} space={item} />;
                      }
                    })}
                  </Flex>
                </Flex>
                <Flex direction={"column"} width={"100%"}>
                  <Text
                    fontSize={{
                      base: "18px",
                      small: "22px",
                      medium: "25px",
                    }}
                    fontWeight="500"
                  >
                    Spaces in All Markets:
                  </Text>
                  <Flex
                    wrap={"wrap"}
                    gap={{
                      base: "5px",
                      small: "15px",
                    }}
                  >
                    {spaceTemplates.map((item, index) => {
                      console.log(spaceImages);
                      return <SpaceCard key={index} space={item} />;
                    })}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </>
        )
      ) : (
        <>
          <SpaceSelectorForm
            selectedSpace={selectedSpace}
            setSelectedSpace={setSelectedSpace}
            tempSpaceInputs={tempSpaceInputs}
            setTempSpaceInputs={setTempSpaceInputs}
            mode={mode}
            setAddNewSpace={setAddNewSpace}
            spaces={spaces}
          />
        </>
      )}
    </Flex>
  );
};

export default SpaceSelector;
